@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

*,
*:before,
*:after {
	box-sizing: border-box !important;
}

html {
	/*background: #eeeeee;*/
}

body {
	width: 100%;
	min-height: 100vh;
	margin: 0;
	padding: 0 16px;
	font-family: 'Spoqa Han Sans Neo', SpoqaHanSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.svg--animated {
	-webkit-transition: all 250ms ease-in-out;
	-moz-transition: all 250ms ease-in-out;
	-ms-transition: all 250ms ease-in-out;
	-o-transition: all 250ms ease-in-out;
	transition: all 250ms ease-in-out;
}

a {
	color: inherit;
	text-decoration: none;
}